import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Priser</h1>
      <table>
        <thead>
          <tr>
            <th>
              Konsultasjon Seniorterapaut (+10 år erfaring){' '}
              <em className="text-secondary">* / **</em>
            </th>
            <th>Priser</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1 gangs konsultasjon (Senionkiropraktor)</td>
            <td>990 kr</td>
          </tr>
          <tr>
            <td>Oppfølgende behandling (Senionkiropraktor)</td>
            <td>790 kr</td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th>
              Krystallsykebehandling
            </th>
            <th>Priser</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Første konsultasjon</td>
            <td>1690 kr</td>
          </tr>
          <tr>
            <td>Oppfølgende behandling</td>
            <td>990 kr</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>
              Akutt timer (utenom vanlig arbeidstid){' '}
              <em className="text-secondary">* / **</em>
            </th>
            <th>Priser</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Helg 09:00-11:00 (ring/book online)</td>
            <td>1490 kr</td>
          </tr>
          <tr>
            <td>Helg etter 11:00 (ring for avtale)</td>
            <td>1690 kr</td>
          </tr>
          <tr>
            <td>Hverdag etter 16:00 (ring for avtale)</td>
            <td>1690 kr</td>
          </tr>
        </tbody>
      </table>
      <div>
        <em className="text-secondary">
          * Vanlig arbeidstid 8:00-16:00 hverdager
        </em>
      </div>
      <div>
        <em className="text-secondary">
          ** Konsultasjoner som ikke avbestilles innen 24 timer belastes kunden
          i sin helhet.
        </em>
      </div>

      <table>
        <thead>
          <tr>
            <td>Personlig trening med PT utdannet kiropraktor</td>
            <td>Priser</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Per 30min <em className="text-secondary">***</em>
            </td>
            <td>790 kr</td>
          </tr>
        </tbody>
      </table>
      <div>
        <em className="text-secondary">*** kjøp av 10 timer gir 20 % rabatt</em>
      </div>
      <table>
        <thead>
          <tr>
            <td>Dokumenter</td>
            <td>Priser</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Henvisning til rtg/mr o.l</td>
            <td>350 kr</td>
          </tr>
          <tr>
            <td>Sykemelding</td>
            <td>150 kr</td>
          </tr>
          <tr>
            <td>Utskrift av journal</td>
            <td>150 kr</td>
          </tr>
          <tr>
            <td>Tillegg trykkbølge</td>
            <td>180 kr</td>
          </tr>
          <tr>
            <td>Therabånd (strikk)</td>
            <td>150 kr</td>
          </tr>
        </tbody>
      </table>

      <p>
        Avbestilling kan gjøres via telefon:{' '}
        <a href="tel:+47452 81 030">452 81 030</a>
      </p>
    </Layout>
  )
}

export default Page
